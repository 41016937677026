// style
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Suspense, lazy } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AboutPage from "./pages/about/AboutPage";
// import HomePage from "./pages/home/HomePage";

// import Footer from "./components/footer/Footer";
// import Header from "./components/header/Header";
// import DesignerPage from "./pages/designer/DesignerPage";
// import PortfolioPage from "./pages/portfolio/PortfolioPage";
// import DetailsOfPortfolio from "./pages/portfolio/details/DetailsOfPortfolio";
// import ContactPage from "./pages/contact/ContactPage";
// import ScrollToTop from "./hooks/ScrollToTop";
// import VideoTour from "./pages/videos/VideoTour";
const HomePage = lazy(() => import('./pages/home/HomePage'));
const AboutPage = lazy(() => import('./pages/about/AboutPage'));
const Footer = lazy(() => import('./components/footer/Footer'));
const Header = lazy(() => import('./components/header/Header'));
const DesignerPage = lazy(() => import('./pages/designer/DesignerPage'));
// const PortfolioPage = lazy(() => import('./pages/portfolio/PortfolioPage'));
const DetailsOfPortfolio = lazy(() => import('./pages/portfolio/details/DetailsOfPortfolio'));
const ContactPage = lazy(() => import('./pages/contact/ContactPage'));
const ScrollToTop = lazy(() => import('./hooks/ScrollToTop'));
const VideoTour = lazy(() => import('./pages/videos/VideoTour'));

function App() {
  return (
    <BrowserRouter>
          <Suspense fallback={<div className="forLoading" >
            <div className="loadingIcon">

            </div>
          </div>}>

      <Header />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/designer" element={<DesignerPage />} />
        {/* <Route path="/portfolio" element={<PortfolioPage />} /> */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/video" element={<VideoTour />} />

        <Route path="/portfolio/:id" element={<DetailsOfPortfolio />} />
      </Routes>

      <Footer />
      </Suspense>

    </BrowserRouter>
  );
}

export default App;
